<template>
  <div class="page">
    <div class="nav">
      <div>
        <a href="https://gtokentool.com">
          <img
            class="image_1"
            referrerpolicy="no-referrer"
            src="../assets/logo.png"
          />
        </a>
        <div class="connect">
          <div>
            <el-dropdown @command="translate">
              <span class="el-dropdown-link language">
                {{ $t("home.language") }}
                <img src="../assets/title.png" alt="" />
              </span>
              <!-- <div style="width:1.875rem;height:1.875rem;margin-top: 2.125rem;    margin-right: 1.875rem;
    text-align: center;">
            
          </div> -->
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="en">English</el-dropdown-item>
                  <el-dropdown-item command="zh">中文</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>

          <div class="text-wrapper_1 flex-col">
            <span class="text_2" @click="line">{{ ownadress }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="group_3 flex-col"></div>
    <div class="center">
      <div class="left">
        <div>
          <span class="text_3">{{ $t("home.welcom") }} {{ project }}</span>
        </div>
        <div class="de">
          <span class="text_4">{{ $t("home.introduce") }}</span>
        </div>
        <div class="group_5 flex-col"></div>
        <div class="paragraph_1">
          <!--          {{ $t("home.zongshu") }} <span>{{ totalSupply * needAmount }} {{ tokenSymbol }}</span>-->
          <!--          <br />-->
          {{ $t("home.zhuzao") }} <span>{{ totalSupply }}</span>
          <br />
          {{ $t("home.Surplus") }} <span>{{ maxNftAmount - totalSupply }}</span>
          <br />
          {{ $t("home.Personal") }}
          <span>{{ limit == 100000000 ? $t("home.infinite") : limit }}</span>
          <br />
          {{ $t("home.Address") }} <span @click="copyAddress">{{ addr2 }}</span>
        </div>
      </div>
      <div class="right">
        <div class="block_3 flex-col">
          <span style="position: relative; z-index: 2" class="text_5"
            >{{ $t("home.Spend") }}{{ needAmount }}{{ tokenSymbol }}</span
          >

          <div class="text-wrapper_2 flex-col">
            <el-button
              :plain="true"
              class="text_6"
              @click="dialogVisible = true"
              >{{ $t("home.CastNFT") }}</el-button
            >
          </div>
          <img
            style="z-index: 1"
            class="image_2"
            referrerpolicy="no-referrer"
            src="../assets/nft(1).png"
          />
        </div>

        <!-- 弹窗 -->
        <el-dialog
          v-model="dialogVisible"
          class="mintAmount"
          :before-close="handleClose"
        >
          <span>{{ $t("home.InputCastAmount") }}</span>
          <el-input-number v-model="num" :min="0" />
          <p style="margin-top: 5px">
            {{ $t("home.Tip")
            }}<span style="color: red">{{
              maxNftAmount - totalSupply > limit - userInfo.balance
                ? limit - userInfo.balance
                : maxNftAmount - totalSupply
            }}</span>
          </p>
          <template #footer>
            <div class="dialog-footer">
              <el-button type="primary" @click="mint" :loading="isLoading">
                <span style="color: white">
                  {{ $t("home.Confirm") }}
                </span>
              </el-button>
              <!-- <span>提示：</span> -->
            </div>
          </template>
        </el-dialog>
      </div>
    </div>

    <div class="group_6 flex-col">
      <div class="group_7 flex-row justify-between">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="../assets/master.png"
        />
        <span class="text_7">{{ $t("home.Account") }}</span>
      </div>
      <div class="group_8 flex-col"></div>
      <div class="group_9 flex-row">
        <div class="amount">
          <div id="group_10">
            <!-- <span class="text_8">65%</span> -->
            <!-- 65% -->
          </div>
          <div class="group_11 flex-col">
            <div class="text-wrapper_3">
              <span class="text_9">{{ $t("home.Amount") }}</span>
              <span>{{ userInfo.balance }}</span>
            </div>
            <!-- <div class="text-wrapper_4">
              <span class="text_11">NFT&nbsp;ID</span>
              <span class="text_12">：</span>

            </div> -->
            <div class="text-wrapper_5">
              <span class="text_13">{{ $t("home.invitees") }}</span>
              <span>{{ userInfo.inviteesAmount }}</span>
            </div>
            <div class="text-wrapper_6">
              <span class="text_14">{{ $t("home.Award") }}:</span>
              <span>{{ userInfo.divAmount }}{{ tokenSymbol }}</span>
            </div>
          </div>
        </div>
        <div class="group_12 flex-col"></div>
        <div class="group_13 flex-col">
          <div class="group_14 flex-row justify-between">
            <span class="text_15">{{ $t("home.Invitelink") }}</span>
            <img
              class="image_5"
              referrerpolicy="no-referrer"
              src="../assets/link.png"
            />
          </div>
          <div class="text_16">
            <!-- https://nft.gtokentool.com/?addr=&amp;invite=&amp;chainId= -->
            {{ link }}
          </div>
          <div class="text-wrapper_5 flex-col">
            <span @click="copyLink" class="text_17">{{
              $t("home.Copylink")
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 邀请规则 -->
    <div class="inviteInfo" v-show="inviteesArr[0] != 0">
      <p class="inviteTittle">{{ $t("home.Invite") }}</p>
      <div class="inviteNain">
        <span>{{ $t("home.YouCan") }}</span>
        <span v-for="(item, index) in inviteesArr"
          >{{ index + 1 }}{{ $t("home.Level") }}{{ item }}%{{
            $t("home.Rebate")
          }}
          {{ index + 1 == inviteesArr.length ? "。" : "," }}</span
        >
        <!-- {{ inviteesArr }} -->
      </div>
    </div>
    <div class="group_15 flex-row">
      <div class="section_1 flex-col"></div>
      <div>
        <img
          class="image_6"
          referrerpolicy="no-referrer"
          src="../assets/partners.png"
        />
        <span class="text_18">{{ $t("home.partners") }}</span>
      </div>
      <div class="section_2 flex-col"></div>
    </div>
    <div class="group_16 flex-col">
      <div class="image-wrapper_1">
        <div>
          <img referrerpolicy="no-referrer" src="../assets/partner1.png" />
        </div>
        <div>
          <img referrerpolicy="no-referrer" src="../assets/partner2.png" />
        </div>
        <div>
          <img referrerpolicy="no-referrer" src="../assets/partner3.png" />
        </div>
        <div>
          <img referrerpolicy="no-referrer" src="../assets/partner4.png" />
        </div>
        <div>
          <img referrerpolicy="no-referrer" src="../assets/partner5.png" />
        </div>
        <div>
          <img referrerpolicy="no-referrer" src="../assets/partner6.png" />
        </div>
        <div>
          <img referrerpolicy="no-referrer" src="../assets/partner7.png" />
        </div>
        <div>
          <img referrerpolicy="no-referrer" src="../assets/partner8.png" />
        </div>
        <div></div>
      </div>
    </div>
    <div class="group_17 flex-row">
      <div class="block_4 flex-col justify-between">
        <div>
          <img
            class="image_8"
            referrerpolicy="no-referrer"
            src="https://lanhu.oss-cn-beijing.aliyuncs.com/psofe731igwf203gmha5xqdculjl5psizb89f16753-495a-44a3-b4a7-dbf6977a9fd4"
          />
        </div>
        <div class="text-wrapper_6">
          <span class="text_19">GTokenTool</span>
          <!--          <span class="text_20">dog</span>-->
          <span class="text_21">
            {{ $t("home.foot") }}
          </span>
        </div>
      </div>
      <div class="block_5"></div>
      <div class="block_6">
        <div class="text_22">{{ $t("home.contact") }}</div>
        <img
          @click="contact"
          class="image_9"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/pstgg3dmf3zzhmhw8v5o0wglf7048uqsl7d9fc847-6689-4daa-9070-954d93dc8a35"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import i18n from "../language/index.js";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
import { ArrowDown } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import "element-plus/dist/index.css";
import { ERC20ABI, NFTABI, NetWorkConfig } from "../config";

import { ethers } from "ethers";
import { onMounted, onBeforeMount, ref, reactive, watch } from "vue";
import * as echarts from "echarts";
import copy from "copy-to-clipboard";
import { getEther } from "../utils";
// import {NetWorkConfig} from '../config/config.js'

const ownadress = ref("Connect Wallet");
const account = ref("");

const dialogVisible = ref(false);
const num = ref(1);

const totalAmount = ref("");
const invite = ref("");
const chainId = ref("");
const addr = ref("");
const addr2 = ref("");
const link = ref("");
const isLoading = ref(false);

//代币简称
const token = ref("");
const tokenSymbol = ref("");
const tokendecimals = ref("");

//config参数
const project = ref("");
const needAmount = ref("");
const maxNftAmount = ref("");
const limit = ref("");
const contactUs = ref("");
const isNative = ref(0);

const totalSupply = ref("");
const inviteesArr = reactive([]);

//user参数
const userInfo = reactive({
  isMint: false,
  balance: "",
  divAmount: "",
  inviteesAmount: "",
});

onMounted(() => {
  line();
  getLink();
  getUserInfo();
});

watch(dialogVisible, () => {
  num.value = 0;
});

// 连接钱包
const line = async () => {
  console.log("没连钱包");
  if (window.ethereum) {
    const web3Provider = window.ethereum;
    try {
      const addressArray = await web3Provider.request({
        method: "eth_requestAccounts",
      });
      console.log("连接钱包");

      account.value = addressArray[0];
      ownadress.value =
        addressArray[0].slice(0, 4) + "..." + addressArray[0].slice(-4);

      const provider = new ethers.providers.Web3Provider(window["ethereum"]);
      const Network = await provider.getNetwork();

      getLink();

      // 监听账户列表的变化
      ethereum.on("accountsChanged", async (accounts) => {
        // 当账户列表发生变化时，重新调用 line 方法
        await line();
        await getLink();

        await getUserInfo();
      });
    } catch (error) {
      console.log(error, "error");
    }
  } else {
    ElMessage.error("请安装小狐狸钱包!");
  }
};

// 生成分享链接
const getLink = async () => {
  //获取
  const host = window.location.search;

  if (host) {
    let params = new URLSearchParams(host);
    let [_addr, _chainId] = [params.get("addr"), params.get("chainId")];
    console.log(params);
    if (_addr) {
      addr.value = _addr;
      addr2.value = _addr.slice(0, 4) + "..." + _addr.slice(-4);
    }
    if (_chainId) chainId.value = _chainId;
  }
  if (window.ethereum) {
    const web3Provider = window.ethereum;
    const addressArray = await web3Provider.request({
      method: "eth_requestAccounts",
    });
    let linkAccount = addressArray[0];

    link.value = `${window.location.origin}/?addr=${addr.value}&invite=${linkAccount}&chainId=${chainId.value}`;
  }
};

//铸造
const mint = async () => {
  isLoading.value = true;

  if (!account.value) {
    locale.value == "en"
      ? ElMessage.error("Please connect the wallet first")
      : ElMessage.error("请先连接钱包");

    dialogVisible.value = false;
    isLoading.value = false;

    return;
  }

  if (num.value == 0) {
    locale.value == "en"
      ? ElMessage.error("Please enter the casting quantity")
      : ElMessage.error("请输入铸造数量");
    isLoading.value = false;
    return;
  } else if (
    maxNftAmount.value - totalSupply.value < num.value ||
    num.value > limit.value - userInfo.balance
  ) {
    // ElMessage.error($t("home.MaximumLimit"))
    locale.value == "en"
      ? ElMessage.error("The casting quantity has reached the maximum limit")
      : ElMessage.error("铸造数量已达最大限制");
    isLoading.value = false;
    return;
  }
  // else if(){
  //   locale.value == "en" ? ElMessage.error("The casting quantity has reached the maximum limit") : ElMessage.error("铸造数量已达最大限制")
  //   isLoading.value = false;
  // }
  const host = window.location.search;
  let _invite;
  if (host) {
    let params = new URLSearchParams(host);
    _invite = params.get("invite");
  }
  let part = _invite ? _invite : account.value;

  try {
    // const rpc = NetWorkConfig[chainId.value].rpcUrls.default;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(token.value, ERC20ABI, signer);

    let allowance = await tokenContract.allowance(account.value, addr.value);
    const nftContract = new ethers.Contract(addr.value, NFTABI, signer);
    let _needAmount = ethers.utils.parseUnits(
      (needAmount.value * num.value).toString(),
      tokendecimals.value
    );
    console.log(isNative.value, "isNative");
    if (!isNative.value) {
      if (allowance.lt(_needAmount)) {
        console.log("授权");
        await (await tokenContract.approve(addr.value, _needAmount)).wait();
      }

      await (await nftContract.mintTo(part, num.value)).wait();
    } else {
      await (
        await nftContract.mintTo(part, num.value, {
          value: _needAmount,
        })
      ).wait();
    }

    // ElMessage.success($t("home.Success"))
    locale.value == "en"
      ? ElMessage.success("Success")
      : ElMessage.success("铸造成功！");
    getUserInfo();

    dialogVisible.value = false;
    isLoading.value = false;
  } catch (error) {
    console.log(error);
    dialogVisible.value = false;
    isLoading.value = false;
  }
};

// 中英文转换
const translate = (lang) => {
  locale.value = lang;
  console.log(lang);
};

// 获取用户信息
const getUserInfo = async () => {
  console.log("没连钱包");

  try {
    // 判断是否有上级地址
    const host = window.location.search;
    console.log(host, "host");

    if (!host) return;
    let params = new URLSearchParams(host);
    let _addr = params.get("addr");
    let _chainId = params.get("chainId");

    const rpc = NetWorkConfig[_chainId].rpcUrls.default;
    console.log(_addr, "_addr");

    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const NFTContract = new ethers.Contract(_addr, NFTABI, provider);

    // configinfo
    const res4 = await NFTContract.getConfig();

    console.log(res4, "res");
    project.value = res4[0][1]; //项目名称
    let _needAmount = res4[1][0]; //需要金额
    maxNftAmount.value = res4[1][1]; //最大限制
    limit.value = res4[1][2]; //单人限制
    contactUs.value = res4[0][5];
    token.value = res4[2][1]; //代币

    let _native = res4[1][3]; //是否主币
    let _inviteArr = res4[3];

    //邀请率
    for (let i = 0; i < _inviteArr.length; i++) {
      inviteesArr[i] = parseInt(_inviteArr[i]) / 100;
    }

    console.log(inviteesArr, "arr");

    isNative.value = parseInt(_native);

    const erc20Contract = new ethers.Contract(token.value, ERC20ABI, provider);
    tokenSymbol.value = await erc20Contract.symbol();
    tokendecimals.value = await erc20Contract.decimals();

    //需要多少代币
    needAmount.value = getEther(_needAmount, tokendecimals.value);

    const res2 = await NFTContract._totalSupply();
    totalSupply.value = parseInt(res2);

    console.log(res2, "res5");
    //邀请率
    // const res5 = await NFTContract._invitees();

    //比重
    const chartDom = document.getElementById("group_10");
    var myChart = echarts.init(chartDom);
    var option;
    option = {
      series: [
        {
          type: "pie",
          radius: ["45%", "60%"],
          avoidLabelOverlap: false,
          labelLine: {
            show: false,
          },
          data: [
            {
              value: parseInt(userInfo.balance),
              itemStyle: {
                color: "#E0FF66",
              },
            },
            {
              value:
                totalSupply.value == 0
                  ? 1
                  : totalSupply.value - parseInt(userInfo.balance),
              itemStyle: {
                color: "white",
              },
            },
          ],
        },
      ],
    };
    option && myChart.setOption(option);
    if (window.ethereum) {
      const web3Provider = window.ethereum;
      const addressArray = await web3Provider.request({
        method: "eth_requestAccounts",
      });
      let _account = addressArray[0];
      //userInfo
      if (!_account) return;
      console.log("连了钱包");

      const res = await NFTContract.getUser(_account);

      console.log(res, "res.userInfo");
      userInfo.isMint = res[0];
      userInfo.balance = res[1];
      userInfo.inviteesAmount = res[4].length;
      //邀请奖励
      userInfo.divAmount = getEther(res[2], tokendecimals.value);
      //nftid
      console.log(_account, "accouint");
      const res3 = await NFTContract.tokensOfOwner(_account);

      console.log(res3, "res3");
    }
  } catch (error) {
    console.log(error, "error");
  }
};

// 复制分享链接
const copyLink = async () => {
  if (link.value != "") {
    copy(link.value);
    ElMessage.success("复制成功!");
  }
};

// 复制账户地址
const copyAddress = async () => {
  if (addr != "") {
    copy(addr.value);
    ElMessage.success("复制成功!");
  }
};

const contact = () => {
  console.log(contactUs,'contactUs');
  window.location.href = contactUs.value;
};
</script>
<style>
@import "../css/HomeView.css";

@font-face {
  font-family: "Bimini";
  src: url("../fonts/Bimini.TTF") format("truetype");
}
</style>
